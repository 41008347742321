const TPromise = require('promise');
const giftcert = require('../giftcert');
const tooltip = require('../tooltip');
const util = require('../util');
const dialog = require('../dialog');
const page = require('../page');
const login = require('../login');
const progress = require('../progress');
const validator = require('../validator');
const addToCart = require('./product/addToCart');
const paymentutils = require('../paymentutils');
const qas = require('../../../../int_QASc/cartridge/js/qas');

function initializePaymentForm() {
    $('#CreditCardForm').on('click', '.cancel-button', (e) => {
        e.preventDefault();
        dialog.close();
    });
}

function submitDelete(targetUrl) {
    const promise = new TPromise((resolve, reject) => {
        $.ajax({
            url: targetUrl,
            dataType: 'json',
        }).done((data) => {
            if (data.status.toLowerCase() === 'ok') {
                resolve(Urls.addressesList);
            } else if (data.message.length > 0) {
                reject(data.message);
            } else {
                reject();
            }
        });
    });
    return promise;
}

/**
 * @private
 * @function
 * @description Triggers dialog for address deletion
 */
function initAddressDelete(el, targetUrl) {
    const dialogTitle = ($(el).attr('title')) ? $(el).attr('title') : Resources.DELETE_TITLE;
    const message = String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS);
    let confirmDelete = false;

    dialog.open({
        html: message,
        options: {
            height: 'auto',
            width: 500,
            title: dialogTitle,
            dialogClass: 'ui-dialog-delete-confirmation',
            buttons: [
                {
                    text: Resources.REMOVE,
                    class: 'button primary',
                    click() {
                        confirmDelete = true;
                        $(this).dialog('close');
                    },
                },
                {
                    text: Resources.NO_THANKS,
                    class: 'button tertiary',
                    click() {
                        $(this).dialog('close');
                    },
                },
            ],
            close() {
                if (confirmDelete) {
                    submitDelete(targetUrl).then(
                        (data) => {
                            page.refresh(data);
                        },
                        (errorMessage) => {
                            dialog.open({
                                html: `<div class="has-error">${errorMessage}</div>`,
                                options: {
                                    dialogClass: 'ui-dialog-delete-conflict ui-dialog-error',
                                    title: Resources.CANNOT_DELETE,
                                    buttons: [{
                                        text: Resources.OK,
                                        class: 'button primary',
                                        click() {
                                            // confirmDelete = false;
                                            dialog.updateOption('buttons', []);
                                            $(this).dialog('close');
                                        },
                                    }],
                                },
                            });
                        },
                    );
                }
            },
        },
    });
}

/**
 * @function
 * @description Initializes the events on the address form (apply, cancel, delete)
 * @param {Element} form The form which will be initialized
 */
function initializeAddressForm() {
    const $form = $('#edit-address-form');

    $form.find('input[name="format"]').remove();
    tooltip.init();

    $form.on('click', '.apply-button', (e) => {
        if (!$form.valid()) {
            e.preventDefault();
            return;
        }
        dialog.close();
    }).on('click', '.cancel-button, .close-button', (e) => {
        e.preventDefault();
        dialog.close();
    }).on('click', '.delete-button', (e) => {
        e.preventDefault();
        const targetUrl = util.appendParamsToUrl(Urls.deleteAddress, {
            AddressID: $form.find('#addressid').val(),
            format: 'ajax',
        });
        initAddressDelete(e.currentTarget, targetUrl);
    });

    validator.init();
    qas.init();
}
/**
 * @private
 * @function
 * @description Toggles the list of Orders
 */
function toggleFullOrder() {
    $('.order-items').find('li.hidden:first').prev('li').append(`<a class="toggle">${Resources.VIEW_ALL}</a>`).children('.toggle').click((e) => {
        $(e.currentTarget).parent().siblings('li.hidden').show();
        $(e.currentTarget).remove();
    });
}

/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initAddressEvents() {
    const addresses = $('#addresses');
    if (addresses.length === 0) { return; }

    addresses.on('click', '.address-edit, .address-create', (e) => {
        e.preventDefault();
        dialog.open({
            url: e.currentTarget.href,
            options: {
                open: initializeAddressForm,
                title: $(e.currentTarget).data('dlg-title'),
                width: '550',
                buttons: [],
            },
        });
    }).on('click', '.delete', (e) => {
        e.preventDefault();
        const targetUrl = util.appendParamToURL($(e.currentTarget).attr('href'), 'format', 'ajax');
        initAddressDelete(e.currentTarget, targetUrl);
    });
}

/**
 * @private
 * @function
 * @description Sets the pagination view for order history
 */
function setOrderHistoryPage(pageNum, pageSize, pageStart, pageTotal) {
    // show page
    $('.svc-result-items > li').addClass('visually-hidden').filter(`.page${pageNum}`).removeClass('visually-hidden');

    // set paging bar
    $('.search-result-options').load(Urls.orderHistoryPaging, {
        sz: pageSize,
        total: pageTotal,
        start: pageStart,
    });
}
/**
 * @private
 * @function
 * @description Binds the events of the order history page
 */
function initOrderHistoryEvents() {
    const resultItems = $('.svc-result-items');
    const pageSize = parseInt(resultItems.data('pagesize'), 10);
    const pageTotal = resultItems.data('total');

    /*
     * This listens for paging bar link clicks and adjusts the page accordingly
     */
    $('.search-result-options').on('click', '.page-link', (e) => {
        e.preventDefault();

        const pageNum = parseInt($(e.currentTarget).data('page'), 10);
        const pageStart = (pageNum - 1) * pageSize;

        setOrderHistoryPage(pageNum, pageSize, pageStart, pageTotal);

        // push state to browser history
        window.history.pushState({
            pageNum, pageSize, pageTotal, pageStart,
        }, undefined, `?sz=${pageSize}&start=${pageStart}`);

        // scroll back to top
        util.scrollBrowser($('.account-module').offset().top);
    });

    /*
     * Loads or hides order details when a 'View/Hide Order Details' link is clicked
     * var $orderHistoryItem keeps this event from loading on the guest order search since it is a shared template with MyAccount Order History
     */
    const $orderHistoryItem = $('.svc-result-items').not('.order-status-guest').find('.order-history-item-summary');
    $orderHistoryItem.click((e) => {
        e.preventDefault();

        const toggleLink = $(e.currentTarget).find('.order-detail-toggle');
        const orderDetailContainer = toggleLink.parents('.order-history-item').find('.order-details');

        if (orderDetailContainer.hasClass('progress')) {
            return;
        }
        if (orderDetailContainer.hasClass('loaded')) {
            orderDetailContainer.removeClass('loaded').addClass('visually-hidden');
            toggleLink.text(Resources.VIEW_ORDER_DETAILS).removeClass('expanded').closest('li').removeClass('expanded');
        } else {
            // Close all previously open order details
            $('.order-details').removeClass('loaded expanded').addClass('visually-hidden');
            $('.order-detail-toggle').text(Resources.VIEW_ORDER_DETAILS).removeClass('expanded').closest('li')
                .removeClass('expanded');

            orderDetailContainer.removeClass('visually-hidden').addClass('progress');
            progress.show(orderDetailContainer);
            orderDetailContainer.load(util.ajaxUrl(toggleLink.attr('href')), () => {
                orderDetailContainer.removeClass('progress').addClass('loaded');
                toggleLink.text(Resources.HIDE_ORDER_DETAILS).addClass('expanded').closest('li').addClass('expanded');
            });
            // Keep browser viewport on clicked order details
            $('html, body').animate({
                scrollTop: $(toggleLink).parents('li').offset().top,
            }, 200);
        }
    });
    /*
     * this keeps this stores a searched order# in session so if a guest clicks on View order details in gues Order Search it will maintain the order number with MyAccount Order History
     */
    const orderNumber = window.SessionAttributes.ORDERHISTORYNUMBER;
    const orderHistoryEntry = $(`[data-orderid=${orderNumber}]`);
    orderHistoryEntry.find('.order-history-item-summary').click();
    /*
     * Attempts to update an order - Cancel or Accept Item
     */
    $('.svc-result-items').on('click', '.item-action', (e) => {
        e.preventDefault();

        const actionLink = $(e.currentTarget);
        const orderHistoryItem = actionLink.parents('.order-history-item');
        let confirm = false;
        let message;

        // Display a confirmation dialog when canceling anything
        if (actionLink.hasClass('confirm-item')) {
            message = `${actionLink.parents('.line-item').find('.name').data('name')},\n\n${Resources.CANCEL_ITEM_CONFIRM}`;
        } else if (actionLink.hasClass('accept-item')) {
            message = Resources.YOU_ACCEPT_ITEM;
        } else if (actionLink.hasClass('wait-item')) {
            message = Resources.WAIT_ITEM;
        } else if (actionLink.hasClass('cancel-order')) {
            message = Resources.CANCEL_ORDER_CONFIRM;
        }

        const submitModal = () => {
            const promise = new TPromise((resolve, reject) => {
                const data = {
                    lastName: actionLink.data('lastname'),
                    postalCode: actionLink.data('postalcode'),
                };
                const $csrfToken = $('#token');
                data[$csrfToken.prop('name')] = $csrfToken.val();
                const options = {
                    url: util.ajaxUrl(actionLink.attr('href')),
                    method: 'POST',
                    cache: false,
                    data,
                };
                $.ajax(options).done((response) => {
                    if (response.success) {
                        resolve(response.success);
                    } else {
                        reject(Resources.ERROR_ORDER_DETAILS);
                    }
                }).fail(() => {
                    reject(Resources.ERROR_ORDER_DETAILS);
                });
            });
            return promise;
        };

        dialog.open({
            html: message,
            options: {
                height: 'auto',
                width: 500,
                dialogClass: 'ui-dialog-cancel-confirm',
                buttons: [
                    {
                        text: Resources.OK,
                        class: 'button primary',
                        click() {
                            confirm = true;
                            progress.hide();
                            $(this).dialog('close');
                        },
                    },
                    {
                        text: Resources.NO_THANKS,
                        class: 'button tertiary',
                        click() {
                            progress.hide();
                            $(this).dialog('close');
                        },
                    },
                ],
                open() {
                    progress.show(orderHistoryItem);
                },
                close() {
                    progress.hide();

                    if (confirm) {
                        submitModal().then(
                            () => {
                                // Refresh the order details
                                if (orderHistoryItem.find('.order-detail-toggle').length) {
                                    orderHistoryItem.find('.order-details').load(util.ajaxUrl(orderHistoryItem.find('.order-detail-toggle').attr('href')), () => {
                                        progress.hide();
                                    });
                                } else {
                                    const data = {
                                        orderNumber: actionLink.data('orderid'),
                                        lastName: actionLink.data('lastname'),
                                        postalCode: actionLink.data('postalcode'),
                                    };
                                    const $csrfToken = $('#token');
                                    data[$csrfToken.prop('name')] = $csrfToken.val();
                                    // Refresh order details for guests
                                    orderHistoryItem.find('.order-details').load(Urls.orderStatusGuest, data, () => {
                                        progress.hide();
                                    });
                                }
                            },
                            (error) => {
                                dialog.open({
                                    html: `<div class="has-error">${error}</div>`,
                                    options: {
                                        dialogClass: 'ui-dialog-delete-conflict ui-dialog-error',
                                        title: Resources.ERROR,
                                        buttons: [{
                                            text: Resources.OK,
                                            class: 'button primary',
                                            click() {
                                                progress.hide();
                                                $(this).dialog('close');
                                            },
                                        }],
                                    },
                                });
                            },
                        );
                    }
                },
            },
        });
    });

    /*
     * Load page state when navigating back to an order history page
     */
    window.onpopstate = (event) => {
        const {state} = event;
        if (state && state.pageNum) {
            setOrderHistoryPage(state.pageNum, state.pageSize, state.pageStart, state.pageTotal);
        } else {
            setOrderHistoryPage(1, pageSize, 0, pageTotal);
        }
    };

    // Initialize add to cart for buy again
    addToCart();

    // If an order ID was passed as a parameter, open it initially
    $('.search-result-items li .focused-order').addClass('expanded').trigger('click');
}

/**
 * @private
 * @function
 * @description Binds the events of the payment methods list (delete card)
 */
function initPaymentEvents() {
    $('.add-card').on('click', (e) => {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                open: initializePaymentForm,
            },
        });
    });

    /** Begin Other Payment Amount Custom Formatter* */
    let timeout = null;
    $('#dwfrm_accountpayment_otherAmount').on('keyup', () => {
        // Clear current timeout
        clearTimeout(timeout);
        // Reset timeout to 1.5 seconds on keyup
        timeout = setTimeout(() => {
            const $currentFieldValue = $('#dwfrm_accountpayment_otherAmount').val();
            const isValueDecimal = $currentFieldValue.indexOf('.');
            let newDecimalString;
            // If user did not enter a decimal value
            if (isValueDecimal === -1) {
                const lengthOfValue = $currentFieldValue.length;
                /**
                 * If only 2 characters or less we assume this was meant to be a base dollar amount and add the trailing 0's
                 * If more than 2 where added we format the number assuming this was a whole dollar amount and make the last 2 digits follow the decimal
                 * */
                if (lengthOfValue <= 2) {
                    newDecimalString = `${$currentFieldValue}.00`;
                } else {
                    newDecimalString = `${$currentFieldValue.substr(0, lengthOfValue - 2)}.${$currentFieldValue.substr(lengthOfValue - 2)}`;
                }
                const newNumber = parseFloat(newDecimalString).toFixed('2');
                $('#dwfrm_accountpayment_otherAmount').val(newNumber);
            }
        }, 1500);
    });
    /** End Other Payment Amount Custom Formatter* */

    let confirmPayment = false;

    /**
     * @function
     * @description Confirms the payment amount with the customer before submitting the form.
     * @param {Element} form The payment form to be submitted
     * @param {String} paymentAmount The formatted dollar amount of the payment
     */
    function displayConfirmPaymentDialog($form, paymentAmount) {
        const message = `<p>${[
            Resources.PLEASE_CONFIRM_PAYMENT,
            `${Resources.PAYMENT_AMOUNT}<span class="payment-amount">$${paymentAmount}</span>`,
            Resources.CANNOT_CHANGE_AMOUNT,
        ].join('</p><p>')}</p>`;

        dialog.open({
            html: message,
            options: {
                height: 'auto',
                width: 'auto',
                title: Resources.PAYMENT_AMOUNT_VERIFICATION,
                dialogClass: 'ui-dialog-payment-confirmation',
                buttons: [
                    {
                        text: Resources.SUBMIT_PAYMENT,
                        class: 'button primary',
                        click() {
                            confirmPayment = true;
                            $(this).dialog('close');
                        },
                    },
                    {
                        text: Resources.GO_BACK,
                        class: 'button tertiary payment-go-back',
                        click() {
                            $(this).dialog('close');
                        },
                    },
                ],
                close() {
                    if (confirmPayment) {
                        $form.find('button[type=submit]').click();
                    } else {
                        $('button[name=dwfrm_accountpayment_submitPayment]').removeClass('disabled');
                    }
                },
            },
        });
    }

    /** Start Payment Events * */
    // Payment Form Submit Event
    $('#dwfrm_accountpayment').on('submit', (e) => {
        // If the user has already confirmed the payment, submit the form
        if (confirmPayment === true) {
            return true;
        }

        $('.payment-routing.error, .payment.error').hide();
        $('button[name=dwfrm_accountpayment_submitPayment]').addClass('disabled');
        const CCError = $('#dwfrm_accountpayment_paymentMethods_creditCard_number').hasClass('error');
        const CVNError = $('#dwfrm_accountpayment_paymentMethods_creditCard_cvn').hasClass('error');
        const isValidPaymentAmount = paymentutils.validateOtherPaymentAmount();
        const routingNumberResults = paymentutils.validateRoutingNumber();
        let formHasErrors = false;
        // Loop through  the routingNumberResults which contains any visible routing number field with it associated validation results
        Object.keys(routingNumberResults).forEach((field) => {
            if (!routingNumberResults[field]) {
                // Retrieve the current routingNumberResults field associated error mesage and display it
                $(`input[name=${field}]`).closest('#routing-container').siblings('.payment-routing.error').show();
                formHasErrors = true;
            }
        });

        // If payment amount is not valid display error
        if (!isValidPaymentAmount) {
            $('.payment.error').show();
            formHasErrors = true;
        }
        // If form has payment, routing number, orr cc number or cvn error cancel form submit and remove the disabled property and disabled class
        if (formHasErrors || CCError || CVNError) {
            $('button[name=dwfrm_accountpayment_submitPayment]').removeClass('disabled');
            return false;
        }

        // If the customer has selected the minimum payment amount, do not show the confirmation dialog.
        if ($('#dwfrm_accountpayment_amountChoice_minimumPayment').is(':checked')) {
            return true;
        }

        // If the customer is simply enrolling in autopay without making a payment, submit the form
        if ($('.no-payment-required').length > 0) {
            return true;
        }

        // If the customer entered a different amount, use that value, otherwise pay the full balance.
        let paymentAmount;
        if ($('#dwfrm_accountpayment_amountChoice_other').is(':checked')) {
            paymentAmount = $('#dwfrm_accountpayment_otherAmount').val();
        } else {
            paymentAmount = $('.payment-row.other-payment label').first().data('currentbalance');
        }

        paymentAmount = parseFloat(paymentAmount);
        // The following condition should never happen, but check for paymentAmount is NaN using
        // the fact that only NaN !== NaN.  In this event, something went seriously wrong.  Submit
        // the form, handle the error on the back-end, and refresh the page.
        if (Number.isNaN(paymentAmount)) {
            return true;
        }

        displayConfirmPaymentDialog($(e.currentTarget), paymentAmount.toFixed(2));

        // Do not submit the form at this point; it will be submitted if the customer confirms the amount.
        return false;
    });
    /** End Payment Events * */

    const paymentList = $('.payment-list');
    if (paymentList.length === 0) { return; }

    const deleteCard = (url, formData) => {
        const promise = new TPromise((resolve) => {
            $.ajax({
                type: 'POST',
                url,
                data: formData,
            }).done(() => {
                resolve(util.appendParamToURL(Urls.paymentsList, 'del', 'success'));
            });
        });
        return promise;
    };

    $('form[name="payment-remove"]').on('submit', (e) => {
        e.preventDefault();
        // override form submission in order to prevent refresh issues
        const button = $(e.currentTarget).find('.delete');
        $('<input/>').attr({
            type: 'hidden',
            name: button.attr('name'),
            value: button.attr('value') || 'delete card',
        }).appendTo($(e.currentTarget));

        const formData = $(e.currentTarget).serialize();
        const formAction = $(e.currentTarget).attr('action');
        const message = String.format(Resources.CONFIRM_DELETE, Resources.TITLE_CREDITCARD);
        const dialogTitle = Resources.DELETE_TITLE;
        let confirmDelete = false;

        dialog.open({
            html: message,
            options: {
                height: 'auto',
                title: dialogTitle,
                dialogClass: 'ui-dialog-delete-confirmation',
                buttons: [
                    {
                        text: Resources.REMOVE,
                        class: 'button primary',
                        click() {
                            confirmDelete = true;
                            $(this).dialog('close');
                        },
                    },
                    {
                        text: Resources.NO_THANKS,
                        class: 'button tertiary',
                        click() {
                            $(this).dialog('close');
                        },
                    },
                ],
                close() {
                    if (confirmDelete) {
                        deleteCard(formAction, formData).then((data) => {
                            page.redirect(data);
                        });
                    }
                },
            },
        });
    });

    // Updates expiration update submit button text when interacting with expiration month/year dropdowns
    paymentList.on('change', 'select', (e) => {
        const updateForm = $(e.currentTarget).parents('form');
        if (updateForm.length > 0 && updateForm.find('button').length > 0) {
            const updateBtn = updateForm.find('button');
            updateBtn.text(updateBtn.attr('value')).removeClass('error-icon');
        }
    });
}

/**
 * @private
 * @function
 * @description Updates payment method for make a payment form
 */
function updatePaymentMethod(element) {
    element.parents('.payment-methods').find('.payment-method-type').hide();
    element.parents('.payment-row').find('.payment-method-type').show();
}

/**
 * @private
 * @function
 * @description Binds the events for make a payment form
 */
function initializeAccountPaymentForm() {
    const $selectPaymentAmount = $('input[name$="_accountpayment_amountChoice"]');
    const $otherAmount = $('input[name$="_accountpayment_otherAmount"]');

    $selectPaymentAmount.on('change', (e) => {
        $otherAmount.toggleClass('required', $(e.currentTarget).val() === 'other' && $(e.currentTarget).is(':checked'));
    }).trigger('change');

    // Auto-select 'other' radio when customer starts entering an amount in the other amount input field
    $otherAmount.on('keydown', () => {
        $('input[id$="_amountChoice_other"]').click();
    });

    $('input[name$="_accountpayment_paymentMethods_selectedPaymentMethodID"]').on('click', (e) => {
        updatePaymentMethod($(e.currentTarget));
    }).filter(':checked').each((index, element) => {
        updatePaymentMethod($(element));
    });

    $('input[name$="_accountpayment_paymentMethods_ach_newOrRecent"]').on('change', (e) => {
        $('.ach-form-container').toggleClass('hide', $(e.currentTarget).val() !== 'new');
    });
}

/**
 * @function
 * @description Clears the Credit Card form by setting fields back to their initial values.
 */
function clearPaymentCreditCardFields(container) {
    const $creditCard = container.find('[data-method="CREDIT_CARD"]');
    $creditCard.find('input[name$="creditCard_owner"]').val('').trigger('change');
    $creditCard.find('select[name$="_type"]').val($creditCard.find('select[name$="_type"] option:first').val()).trigger('change');
    $creditCard.find('input[name*="_creditCard_number"]').val('').trigger('change');
    $creditCard.find('[name$="_month"]').val($creditCard.find('[name$="_month"] option:first').val()).trigger('change');
    $creditCard.find('[name$="_year"]').val($creditCard.find('[name$="_year"] option:first').val()).trigger('change');
    $creditCard.find('input[name$="_cvn"]').val('').trigger('change');
}

/**
 * @private
 * @function
 * @description Binds the events for the credit card fields on the make a payment form
 */
function initializePaymentCreditCard() {
    $('input[name$="_accountpayment_paymentMethods_creditCardList"]').on('change', (e) => {
        const cardUUID = $(e.currentTarget).val();
        const isSavedCard = cardUUID && cardUUID.length > 0;
        const $container = $(e.currentTarget).parents('.payment-method-type');
        const $creditCardFields = $container.find('.credit-card-fields');
        const $saveCard = $container.find('input[name$="_accountpayment_paymentMethods_creditCard_saveCard"]');

        $saveCard.prop('checked', !isSavedCard);
        $creditCardFields.toggle(!isSavedCard);
        clearPaymentCreditCardFields($container);
    }).filter(':checked').trigger('change');
}

/**
 * @private
 * @function
 * @description Initializes the change account and change plan dialog forms
 */
function initializeAccountDialog() {
    validator.init();

    $('.cancel').click((e) => {
        e.preventDefault();
        dialog.close();
    });

    const $form = $('#ChangeAccountDialog, #ChangePlanDialog');
    const $submit = $form.find('button');

    $($submit).on('click', (e) => {
        const $triggeredSubmit = $(e.currentTarget);
        const $triggeredForm = $triggeredSubmit.parents('form');
        if (!$triggeredForm.valid()) {
            return;
        }
        e.preventDefault();
        let data = $triggeredForm.serialize();
        // add form action to data
        data += `&${$triggeredSubmit.attr('name')}=`;
        // make sure the server knows this is an ajax request
        if (data.indexOf('ajax') === -1) {
            data += '&format=ajax';
        }
        $.ajax({
            type: 'POST',
            url: $triggeredForm.attr('action'),
            data,
            success(response) {
                if (typeof response === 'object' && !response.success && response.error === 'CSRF Token Mismatch') {
                    page.redirect(Urls.csrffailed);
                } else if (typeof response === 'object') {
                    // Object response either means the request succeeded or the customer is no longer authenticated
                    page.refresh();
                } else if (typeof response === 'string') {
                    dialog.$container.html(response);
                    initializeAccountDialog();
                }
            },
            failure() {
                dialog.$container.html(`<h1>${Resources.SERVER_ERROR}</h1>`);
            },
        });
    });
}

/**
 * @private
 * @function
 * @description Initializes the Email Sign-On & Settings events
 */
function initEmailUpdateEvents() {
    // Handle email form submissions
    $('.account-email-preferences').on('submit', 'form', (e) => {
        e.preventDefault();

        const $form = $(e.currentTarget);
        const module = $form.parents('.account-module');
        const url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
        const applyName = $form.find('.apply-button').attr('name');

        progress.show(module);

        const options = {
            url,
            data: `${$form.serialize()}&${applyName}=x`,
            type: 'POST',
        };
        $.ajax(options).done((data) => {
            module.html(data);
            validator.init();
            progress.hide();
        });
    }).on('click', '.account-module-actions', (e) => {
        e.preventDefault();
        $(e.currentTarget).parents('.account-module').toggleClass('active');
    }).on('click', '.cancel', (e) => {
        e.preventDefault();
        $(e.currentTarget).parents('.account-module').removeClass('active').find('form').trigger('reset');
    }).on('click', '.subscription-update', (e) => {
        e.preventDefault();

        const module = $(e.currentTarget).parents('.account-module');

        progress.show(module);

        const url = $(e.currentTarget).prop('href');
        const csrf = module.find('.csrf');
        const csrfName = csrf.prop('name');
        const csrfValue = csrf.prop('value');

        const options = {
            url: util.appendParamToURL(url, csrfName, csrfValue),
            type: 'POST',
        };
        $.ajax(options).done((data) => {
            module.html(data);
            validator.init();
            progress.hide();
        });
    });
    window.onload = function onload() {
        if (window.location.hash) {
            const target = $(window.location.hash);
            if (target.length > 0) {
                target.find('.edit-link').trigger('click');
                $('html, body').animate({scrollTop: target.offset().top}, 400);
            }
        }
    };
}

function initDueDateSelect() {
    const dateCurrent = $('#plan-due-date').attr('value');
    $('select[id$="_account_changeduedate_newDate"] option').each((index, element) => {
        if ($(element).val() === dateCurrent) {
            $(element).remove();
        }
    });
}

function initStatementsList() {
    $('.statements-link').on('click', (e) => {
        e.preventDefault();
        const statementClosing = $(e.currentTarget).data('closing');
        let url = util.appendParamToURL(Urls.openStatement, 'url', statementClosing);
        url = util.ajaxUrl(url);
        $.ajax({
            url,
        }).done((response) => {
            if (!response.success) {
                console.log('eStatement call failure');
            }
            window.open(response.url);
        });
    });
}

/**
 * @private
 * @function
 * @description Binds the events for the date of birth field on the prequalification form
 */
function initPrequalification() {
    $('input[id$="_prequalification_birthdate"]').datepicker({
        changeMonth: true,
        changeYear: true,
        yearRange: '-120:+0',
        minDate: '-120y',
        maxDate: '+0d',
        onClose() {
            $(this).valid();
        },
    });
}

/**
 * @function
 * @description Checks for and removes duplicate addresses in the address book
*/
function checkForDuplicateAddresses(addressBook) {
    const duplicates = addressBook.filter((addr) => {
        const addrBook = addressBook.toArray().map((a) => {
            const name = $(a).find('.mini-address-name').text();
            const location = $(a).find('.mini-address-location').text();
            return `${name}${location}`;
        });
        return addrBook.indexOf(addrBook[addr]) !== addr;
    });
    if (duplicates.length) {
        const deleteDuplicates = new TPromise(() => {
            const duplicateTitles = duplicates.map((i) => $(duplicates[i]).find('.mini-address-title').text());
            duplicateTitles.each((i) => {
                const targetUrl = util.appendParamsToUrl(Urls.deleteAddress, {
                    AddressID: duplicateTitles[i],
                    format: 'ajax',
                });
                submitDelete(targetUrl);
            });
        });
        deleteDuplicates.then((data) => {
            page.refresh(data);
        });
    }
}

/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 */
function initializeEvents() {
    toggleFullOrder();
    initAddressEvents();
    initPaymentEvents();
    initOrderHistoryEvents();
    initEmailUpdateEvents();
    login.init();
    initializeAccountPaymentForm();
    initializePaymentCreditCard();
    initDueDateSelect();
    initStatementsList();
    initPrequalification();

    // Listener to activate change account and plan modals
    $('.change-account, .change-plan').on('click', (e) => {
        e.preventDefault();

        const link = $(e.currentTarget);
        const options = {
            url: util.ajaxUrl($(e.target).attr('href')),
        };
        // Use ajax and check response first in case customer's authenticated session has expired
        $.ajax(options).done((data) => {
            if (typeof (data) === 'object') {
                // If an object is return, customer is logged out
                // Refresh page to send back to login
                page.refresh();
            } else {
                dialog.create({
                    target: $(`#${link.data('dialog')}`),
                    options: {
                        title: link.data('title'),
                        open: initializeAccountDialog,
                    },
                });
                dialog.openWithContent({
                    content: data,
                });
            }
        });
    });

    if ($('div.account-overview').length > 0 && ($('div.account-overview').attr('data-wsc505-return-code') === '02' || $('div.account-overview').attr('data-wsc505-return-code') === '03')) {
        $.ajax({
            url: util.appendParamsToUrl(util.ajaxUrl(Urls.verifyAccountForm), {wsc505ReturnCode: $('div.account-overview').attr('data-wsc505-return-code'), ts: Date.now()}),
        }).done((data) => {
            if (data && $(data).length > 0) {
                dialog.create({
                    options: {
                        title: $('div.account-overview').attr('data-welcome-text'),
                        open() {
                            validator.init();

                            $('#verify-account a.cancel').click((e) => {
                                e.preventDefault();
                                dialog.close();
                            });
                        },
                    },
                });
                dialog.openWithContent({
                    content: data,
                });
            }
        });
    }

    $('#emailsignup-birthday-form #dwfrm_emailsignup__birthday_birthday').datepicker({
        changeMonth: true,
        changeYear: false,
        dateFormat: 'mm/dd',
        yearRange: '2000:2000',
    }).focus(() => {
        $('.ui-datepicker-year').hide();
    });

    // Prevent double-submit on emailsignup forms
    $('#emailsignup-birthday-form input').bind('change', () => {
        $('#emailsignup-birthday-form button[name="dwfrm_emailsignup__birthday_send"]').removeClass('disabled');
    });
    $('#emailsignup-birthday-form').bind('submit', () => {
        if ($('#emailsignup-birthday-form button[name="dwfrm_emailsignup__birthday_send"]').hasClass('disabled')) {
            return false;
        }
        $('#emailsignup-birthday-form button[name="dwfrm_emailsignup__birthday_send"]').addClass('disabled');
        return true;
    });

    util.smartResize(() => {
        if (util.mediaBreakpointUp('md') && $('.account-menu').attr('style')) {
            $('.account-menu').removeAttr('style');
        }
    });

    // Listener for print links
    $('.print-link').on('click', (e) => {
        e.preventDefault();
        window.print();
    });

    const $addressBook = $('#addresses').find('.address-tile');
    checkForDuplicateAddresses($addressBook);
    $addressBook.on('change', () => checkForDuplicateAddresses($addressBook));
}

const account = {
    init() {
        initializeEvents();
        giftcert.init();
    },
    initCartLogin() {
        login.init();
    },
};

module.exports = account;
